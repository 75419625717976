import React                 from 'react';
import { graphql }           from 'gatsby';
import Img                   from 'gatsby-image';
import { Flex, Box, Button } from '@chakra-ui/react';
import { FaHandshake }       from 'react-icons/fa';

import Wrapper from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer  from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import Logo    from '@interness/web-core/src/components/media/Logo/Logo';
import Link    from '@interness/web-core/src/components/elements/Link/Link';

const PartnerPage = (props) => {
  return (
    <Wrapper>
      <Spacer/>
      <Heading icon={<FaHandshake/>}
               subtitle="mit freundlicher Empfehlung von Juwelier Münzer">Hochzeitspartner</Heading>
      <Flex flexWrap="wrap">
        <Box width={['100%', '100%', '100%', '50%']} pr={[0, 0, 0, 6]}>
          <Logo logo={props.data.partnerLogos.media[0].file.localFile} title="Restaurant Oberwaldhaus"
                svgHeight="150px"/>
          <h3>Ihre perfekte Hochzeitsfeier</h3>
          <p>
            Seit Jahrzehnten steht unser Haus für perfekte Hochzeitsfeier.<br/><br/> Profitieren Sie von unserer
            langjährigen Erfahrung und unserem engagierten und erfahrenen Team, wir sorgen dafür, dass Ihre Feier zu
            einem unvergesslichen Erlebnis wird, an das sich alle Gäste gerne zurückerinnern. <br/><br/>Im Sommer, bei
            schönem Wetter, lässt sich das Fest inmitten der idyllischen Lage unter schattenspendende Bäume und unter
            unserer überdachten Terrasse im Freien ausdehnen.
            <br/>
            <br/>
            Telefon 06151 712266<br/>
            Telefon 0173 6637525<br/>
            WhatsApp 0173 6637525<br/>
            info@restaurant-oberwaldhaus.com<br/><br/>
            <Button sx={{ textDecoration: 'none' }} variant="solid" colorScheme="brand" as={Link}
                    to="https://hotel-restaurant-oberwaldhaus.com">Zur
              Webseite</Button>
          </p>
        </Box>
        <Box width={['100%', '100%', '100%', '50%']}>
          <Img title="Restaurant Oberwaldhaus"
               fluid={props.data.directusMediaCollection.media[0].file.localFile.childImageSharp.fluid}/>
        </Box>
      </Flex>
      <Spacer/>
    </Wrapper>
  )
};

export default PartnerPage;

export const query = graphql`
    query {
        directusMediaCollection(name: {eq: "partner"}) {
            media {
                file {
                    localFile {
                        name
                        childImageSharp {
                            fluid(maxWidth: 700, cropFocus: CENTER) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        partnerLogos: directusMediaCollection(name: {eq: "partner-logos"}) {
            media {
                file {
                    localFile {
                        name
                        extension
                        publicURL
                    }
                }
            }
        }
    }
`;
